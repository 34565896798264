import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Bio = ({ author }) => {
  const image = getImage(author.avatar);
  return (
    <div className="flex mb-20">
      <GatsbyImage
        className="mr-4 mb-0 rounded-full"
        image={image!}
        alt={author.name}
        style={{
          minWidth: 50,
        }}
      />
      <p>
        Written by <strong>{author.name}</strong>
      </p>
    </div>
  );
};

export default Bio;
