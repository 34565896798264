import React from "react";
import { graphql } from "gatsby";

import Bio from "../../components/bio";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import ConvertKit from "../../components/convertkit-form";

const BlogPostTemplate = ({ data, location }) => {
  const post = data.contentfulBlogPost;
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle}>
      <article className="flex flex-col min-h-screen">
        <header>
          <h1 className="text-5xl font-black mt-8 mb-0">{post.title}</h1>
          <p className="text-sm leading-loose mb-8 ">{post.createdAt}</p>
        </header>
        <section
          className="prose lg:prose-xl dark:prose-invert flex-1"
          dangerouslySetInnerHTML={{
            __html: post.content.childMarkdownRemark.html,
          }}
        />
        <div className="px-4 py-5 sm:p-6 mx-auto max-w-xl">
          <h3 className="text-xl font-medium leading-6 text-gray-900">
            If you thought THAT was valuable, I've got more where that came from
            - and I can make sure you never miss out.
          </h3>
          <ConvertKit />
        </div>
        <hr className="h-px mb-8" />
        <footer className="mt-auto">
          <Bio author={post.author} />
        </footer>
      </article>
    </Layout>
  );
};

export const Head = ({ data }) => {
  const post = data.contentfulBlogPost;
  const ogPublishedAt = new Date(post.createdAt);
  const ogModifiedAt = new Date(post.updatedAt);
  const siteTitle = data.site.siteMetadata.title;
  return (
    <>
      <SEO
        title={post.title || siteTitle}
        ogImageUrl={`https:${post.image?.file?.url}`}
        slug={post.slug}
        description={
          post.description || post.content.childMarkdownRemark.excerpt
        }
      />
      <meta property="article:section" content="Design" />
      <meta property="article:tag" content="Figma" />
      <meta property="article:tag" content="Contentful" />
      <meta
        property="article:published_time"
        content={ogPublishedAt.toISOString()}
      />
      <meta
        property="article:modified_time"
        content={ogModifiedAt.toISOString()}
      />
    </>
  );
};
export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(id: { eq: $id }) {
      description
      id
      title
      slug
      image {
        file {
          url
        }
      }
      content {
        childMarkdownRemark {
          excerpt(pruneLength: 160)
          html
        }
      }
      createdAt(formatString: "MMMM DD, YYYY")
      updatedAt(formatString: "MMMM DD, YYYY")
      author {
        name
        avatar {
          gatsbyImageData(
            formats: AUTO
            width: 75
            height: 75
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;
